import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { Grid, Typography, Link } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { FzForCoachesQuery } from "../../graphql-types";
import {
  BenefitItem,
  ContactUsForm,
  Footer,
  Header,
  HomeHero,
  ImageWithStand,
  MarkdownDescription,
  useFzForCoachesStyles,
  useHomeStyles,
} from "../components";

const anchors = ["productise", "increase-impact", "make-a-difference"];

interface Props {
  data: FzForCoachesQuery;
}

const FzForCoaches: React.FC<Props> = ({ data }: Props) => {
  const { strapiFzForCoachesContent } = data;

  const classes = useFzForCoachesStyles();
  const homeClasses = useHomeStyles();

  return (
    <div>
      <Helmet title="Flourishzone for Coaches">
        <meta name="description" content={strapiFzForCoachesContent.subtitle} />
        <meta name="image" content="/fz-for-coaches-hero.png" />
        <meta property="og:url" content="/fz-for-coaches" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={strapiFzForCoachesContent.subtitle}
        />
        <meta property="og:image" content="/fz-for-coaches-hero.png" />
      </Helmet>

      <Header />

      <HomeHero
        img="/fz-for-coaches-hero.jpg"
        title={strapiFzForCoachesContent.title}
        subtitle={strapiFzForCoachesContent.subtitle}
        tab={1}
        actionLabel={strapiFzForCoachesContent.call_to_action}
      />

      <div className={classes.content}>
        {strapiFzForCoachesContent.images_with_stand.map((section, index) => (
          <Grid
            className={homeClasses.description_section}
            container
            justify="center"
            id={anchors[index]}
            key={section.id}
          >
            <Grid container item xs={11} md={10} spacing={3}>
              <Grid
                className={clsx(homeClasses.order, section.position)}
                item
                xs={12}
                md={6}
              >
                <ImageWithStand
                  src={section.image[0].url}
                  standColor={section.color}
                  standPosition={section.position === "left" ? "right" : "left"}
                  //@ts-ignore
                  adornment={section.adornment}
                />
              </Grid>
              <Grid
                className={clsx(
                  homeClasses.order,
                  section.position === "left" ? "right" : "left"
                )}
                item
                xs={12}
                md={6}
              >
                <MarkdownDescription
                  accentColor={section.color}
                  description={section.description}
                />
                <Link
                  className={clsx(
                    homeClasses.description_item,
                    homeClasses.description_link
                  )}
                  href="https://portal.flourish.zone/register"
                >
                  Find out more
                </Link>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="method-builder"
        >
          <Grid item xs={11} md={10} xl={8}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b>{strapiFzForCoachesContent.benefits_section_title}</b>
              </Typography>
              <Typography variant="body2">
                {strapiFzForCoachesContent.benefits_section_subtitle}
              </Typography>
            </div>
            <Grid container spacing={3}>
              {strapiFzForCoachesContent.benefit_items.map(benefit => (
                <Grid key={benefit.title} item md={3}>
                  <BenefitItem
                    title={benefit.title}
                    description={benefit.description}
                    // @ts-ignore
                    icon={benefit.icon}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="contact-us"
        >
          <Grid item xs={11} sm={10} md={6} lg={5} xl={4}>
            <ContactUsForm />
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  );
};

export default FzForCoaches;

export const query = graphql`
  query FzForCoaches {
    strapiFzForCoachesContent {
      title
      subtitle
      call_to_action
      images_with_stand {
        id
        position
        color
        adornment
        description
        image {
          url
        }
      }
      benefits_section_title
      benefits_section_subtitle
      benefit_items {
        title
        description
        icon
        id
      }
    }
  }
`;
